<template>
    <div>
        <div class="p-grid">

            <div class="p-col-8 p-offset-2 ">

                <h1>INFORMACIÓN BÁSICA SOBRE COOKIES</h1>

                <h2 class="mt-2">POLÍTICA DE COOKIES</h2>
                Información en cumplimiento de la normativa de protección de datos personales
                <br>
                <h3 class="mt-2">• ¿Qué son las cookies y para qué las usamos?</h3>
                Una cookie o galleta informática es un pequeño archivo de información que se guarda en su navegador cada vez
                que visita nuestra página web.
                La utilidad de las cookies es guardar el historial de su actividad en nuestra página web, de manera que,
                cuando la visite nuevamente, ésta pueda identificarle y configurar el contenido de la misma en base a sus
                hábitos de navegación, identidad y preferencias.
                Una cookie es inofensiva, no contiene código malicioso o malintencionado (ej. virus, troyanos, gusanos,
                etc.) que pueda dañar su terminal (ordenador, smartphone, tableta, etc.), pero sí tiene cierto impacto sobre
                su derecho a la protección de sus datos, pues recoge determinada información concerniente a su persona
                (hábitos de navegación, identidad, preferencias, etc.).
                <br>
                <h3 class="mt-2">• ¿Qué información guarda una cookie?</h3>
                Las cookies no suelen recoger categorías especiales de datos personales (datos sensibles). Los datos que
                guardan son de carácter técnico, preferencias personales, personalización de contenidos, etc.
                <br>
                <h3 class="mt-2">• ¿Qué tipo de cookies existen?</h3>
                Con carácter general, existen cinco tipos de cookies:
                <br>
                <h3 class="mt-2">• Cookies técnicas: </h3>
                Son las cookies más básicas. Permiten al usuario la navegación a través de una página web, plataforma o
                aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo,
                controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso
                restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido,
                realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante
                la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de
                redes sociales.
                <br>
                <h3 class="mt-2">• Cookies de personalización: </h3>
                Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general
                predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el
                idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde
                accede al servicio, etc.
                <br>
                <h3 class="mt-2">• Cookies de análisis:</h3>
                Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los
                usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies
                se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración
                de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de
                introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.
                <br>
                <h3 class="mt-2">• Cookies publicitarias:</h3>
                Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en
                su caso, el responsable haya incluido en una página web, aplicación o plataforma desde la que presta el
                servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los
                anuncios.
                <br>
                <h3 class="mt-2">• Cookies de publicidad comportamental: </h3>
                Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en
                su caso, el responsable haya incluido en una página web, aplicación o plataforma desde la que presta el
                servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a
                través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil
                específico para mostrar publicidad en función del mismo.
                <br>
                <h2 class="mt-2">• ¿Qué son las cookies propias y las de terceros?</h2>
                <br>
                <h3 class="mt-2">• Cookies de propias: </h3>
                Las cookies propias son aquellas que se generan y gestionan por el propio responsable que presta el servicio
                solicitado por el usuario.
                <br>
                <h3 class="mt-2">• Cookies de terceros: </h3>
                Son aquellas que se generan por otras entidades distintas al propio responsable (servicios o proveedores
                externos, como por ejemplo Google).
                <br>
                <h3 class="mt-2">• ¿Qué tipo de cookies guarda nuestra página web?</h3>
                A continuación, procedemos a relacionar el tipo de cookies que guarda nuestra página web y la finalidad de
                las mismas:
                Tipo de cookie Finalidad Cuándo y cómo se guarda
                <br>




                <h3 class="mt-2">• ¿Qué puedo hacer con las cookies?</h3>
                Las cookies pueden ser borradas, aceptadas o bloqueadas, según desee, para esto sólo debe configurar
                convenientemente el navegador web.
                En cualquier momento, puede impedir la instalación de cookies (bloqueo) en su equipo mediante la opción
                correspondiente de su navegador, pero en dicho caso no podremos asegurarle el correcto funcionamiento de las
                distintas funcionalidades de nuestra página web.
                A continuación, le facilitamos los enlaces para la gestión y bloqueo de cookies dependiendo del navegador
                que utilice:
                <p classs="">- Internet Explorer:
                    http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-10</p>
                <p classs="">- FireFox:
                    http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</p>
                <p classs="">- Chrome: https://support.google.com/chrome/answer/95647?hl=es</p>
                <p classs="">- Safari: https://www.apple.com/legal/privacy/es/cookies/</p>
                También puede borrar las cookies que tenga guardadas en su navegador acudiendo a las opciones de
                configuración del mismo.
        </div>
    </div>
</div></template>

<script>
export default {
    name: 'CookiesVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>/* Estilos específicos del componente */</style>

